import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import ListingLayout from '../../helpers/components/listing/PageContainer'
import SearchCol from '../../helpers/components/listing/searchCard/SearchCol'
import TableCard from '../../helpers/components/listing/tableCard/TableCard'
import { SearchCaseStudyRequest, useAuthContext, CaseStudiesApi, CaseStudyOrder } from '@pull/pull-groupm-csp-api'
import { QUERIES } from '../../Constants/Queries'
import { PagedResultInterface } from '../../modules/core/_models'
import caseStudyColumns from './table/columns/_columns'
import { CaseStudiesProvider, useCaseStudiesContext } from '../../modules/case-studies/CaseStudyContext'
import { TableProvider, useTableContext } from '../../modules/core/functional/TableContext'
import { ToolbarContainer } from '../../../_metronic/layout/core/ToolbarContainer'


const caseStudiesBreadcrumbs: Array<PageLink> = [
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Case Studies',
    path: '/case-studies',
    isSeparator: false,
    isActive: false,
  },

]

const CaseStudiesPage = () => {
  const { updatePage, updateFilters } = useTableContext()
  const { getApiConfiguration } = useAuthContext()
  const { caseStudyFilters } = useCaseStudiesContext()
  const caseStudyApi = new CaseStudiesApi(getApiConfiguration())

  const getCaseStudies = async (pageParam = 1, filters = {}) => {
    let response = await caseStudyApi.apiCaseStudiesSearchPost({ ...filters, ...{ page: pageParam, pageSize: 10, ignoreDateCutOff: true } } as SearchCaseStudyRequest);
    return response as PagedResultInterface;
  }
  const submitSearchHandler = (searchFields: any) => {
    const tags: Array<number> = [];
    if (searchFields.channel) {
      tags.push(searchFields.channel)
    }

    if (searchFields.objectives) {
      tags.push(searchFields.objectives)
    }

    if (searchFields.categories) {
      tags.push(searchFields.categories)
    }

    if (searchFields.tools) {
      tags.push(searchFields.tools)
    }
    
    const searchRequestObj = {
      keywords: searchFields.keywords,
      selectedAdvertisers: searchFields.advertiser ? [searchFields.advertiser] : undefined,
      selectedBrands: searchFields.client ? [searchFields.client] : undefined,
      selectedTags: tags.length > 0 ? tags : undefined,
      selectedCountries: searchFields.country ? [searchFields.country] : undefined,
      selectedRegions: searchFields.region ? [searchFields.region] : undefined,
      selectedYears: searchFields.year ? [searchFields.year] : undefined,
      selectedAwards: [],
      selectedAwardCategories: [],
      publishedOnly: searchFields.publishedOnly ? true : false,
      ignoreDateCutoff: true,
      order: searchFields.order as CaseStudyOrder
    }
    updateFilters(searchRequestObj)
    updatePage(1)
  }

  return (
    <>
    <ToolbarContainer>
      <PageTitle breadcrumbs={caseStudiesBreadcrumbs} title="Case Studies"></PageTitle>
      </ToolbarContainer>
      <ListingLayout >
      <div className="d-flex flex-column flex-lg-row">
        <SearchCol filters={caseStudyFilters} submitSearch={submitSearchHandler} searchName="keywords" />
        <TableCard tableCols={caseStudyColumns} getData={getCaseStudies} query={QUERIES.CASE_STUDIES.SEARCH}>
        </TableCard>
        </div>
      </ListingLayout>
    </>
  )
}

const CaseStudiesPageWrapper = () => {
  return (
    <CaseStudiesProvider>
      <TableProvider>
        <CaseStudiesPage />
      </TableProvider>
    </CaseStudiesProvider>
  )
}

export { CaseStudiesPage, CaseStudiesPageWrapper } 
